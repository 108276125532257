import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import ZoIcon from '../asset/chat/ZoIcon'
import DefaultProfileIcon from '../asset/chat/DefaultProfileIcon'
import SendIcon from '../asset/chat/SendIcon'
import axios, { CancelTokenSource } from 'axios'
import { UserDataProps } from '../types/common'
import { PulseLoader, FadeLoader, SyncLoader } from 'react-spinners'
import SeenBlueIcon from '../asset/chat/SeenBlueIcon'
import SentWhiteIcon from '../asset/chat/SentWhiteIcon'
import Pusher from 'pusher-js';
import { formatDate, formatTime, isSameDay } from '../helpers/dateTime'
import ScrollDownIcon from '../asset/chat/ScrollDownIcon'
import { TypeAnimation } from 'react-type-animation';
import { useGameStore } from '../store/game'
import DisabledSection from './DisabledSection'
import Blur from '../components/Blur'
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import cone from '../asset/Cones.svg'
import ReactTypingEffect from 'react-typing-effect';
import { Transition } from '@headlessui/react'
import Lottie from 'react-lottie';
import * as xpAdd from '../animations/xpAdd.json'
import LeftPanel from '../components/chat/LeftPanel'
import { Gamification } from '../types/aichat'
import QuestProgressPopUp from '../components/gamification/QuestProgressPopUp'
import { toast } from 'react-toastify'
import ChatGainXpPopUp from '../components/gamification/ChatGainXpPopUp'
import FadeIn from '../animations/FadeIn'
import DangerIcon from '../asset/ineedhelp/DangerIcon'
import { Link } from 'react-router-dom'
import { useInitApi } from '../store/initApi'
import { useChatStore } from '../store/chat'
import { useHomeStore } from '../store/home'
import { track } from '@amplitude/analytics-browser'

export default function Chat() {
    const apiToken = sessionStorage.getItem('api_token')
    const storedUserData = sessionStorage.getItem('user')
    const user: UserDataProps = storedUserData ? JSON.parse(storedUserData) : {}

    const lastSentTime = useRef(Date.now());
    const selectedChatRef = useRef<ChatPersonProps | undefined>();
    const [message, setMessage] = useState<string>("")
    const [isShowScrollDown, setIsShowScrollDown] = useState<boolean>(false)
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const bottomChatRef = useRef<HTMLDivElement | null>(null);
    const [isScrolledToTop, setIsScrolledToTop] = useState(false);
    const [rows, setRows] = useState(1);
    const initApi = useInitApi((state) => state.initApi)
    const disabledRes = useHomeStore((state) => state.home)
    const [tierLists, setTierLists] = useState<TierLists>()
    const { chatHistory, setChatHistory, chatSelected, showDisableZo, isSelectedZo, setIsSelectedZo, isLoading, setIsLoading, zoChat, isLoggedInAi, chatPage, setChatPage, setIsNewChat, zoLoading, setZoLoading, setFilteredChat, tempMsgs, setTempMsgs, chatPersons, setChatPersons, zoTempMsgs, setZoTempMsgs } = useChatStore()
    // ----------------------------------- Zo Hooks -----------------------------------

    const [cursorTyping, setCursorTyping] = useState(true);
    const [fromVibeCheckState, setFromVibeCheckState] = useState(null);
    const [isShowDate, setIsShowDate] = useState<boolean>(false)
    const [isZoError, setIsZoError] = useState(false)
    const [game, setGame] = useState<Gamification>()
    const [isAddXp, setIsAddXp] = useState(false)
    const [zoSenderTempMsg, setZoSenderTempMsg] = useState<ZoSenderTempMsg[]>([])
    const [userMsgCount, setUserMsgCount] = useState(1)
    const [zoMsgCount, setZoMsgCount] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isDisableSend, setIsDisableSend] = useState(false)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: xpAdd,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const seenMsg = async (from_uuid: string) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/chatify/api/makeSeen`, {
                from_uuid: from_uuid,
                api_token: apiToken
            })
            if (res.status === 200) {
                const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/post-chat-search-existing-chat`, {
                    api_token: apiToken
                })
                if (response.status === 200) {
                    setChatPersons(response.data.records)
                    setFilteredChat(response.data.records)
                }
            }

        } catch (error) {
        }
    }


    const updateIncomingMessages = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/post-chat-search-existing-chat`, {
                api_token: apiToken
            })
            if (res.status === 200) {
                setFilteredChat(res.data.records)

            }
        } catch (error) {
        }
    }

    const sendMessage = async () => {
        try {
            // Store the current message value before sending
            const messageToSend = message;
            if (messageToSend.trim() === '') {
                return;
            }
            track('sendMessage', { method: 'attempt' })

            const currentTime = Date.now();
            const timeSinceLastMessage = currentTime - lastSentTime.current;
            if (timeSinceLastMessage < 1000) {
                return;
            }
            lastSentTime.current = currentTime;

            setIsDisableSend(true)
            //Delay 0.5 seconds to send the message
            setTimeout(() => {
                setIsDisableSend(false)
            }, 500);
            if (isSelectedZo) {
                setZoTempMsgs(
                    prev => [
                        ...prev,
                        {
                            type: 'user_message',
                            message: message,
                            count: userMsgCount
                        }
                    ])
                setUserMsgCount(prev => prev + 1)
                setTimeout(() => setZoLoading(true), 2000)

            } else {
                setIsNewChat(false)
                setTempMsgs(prev => [
                    ...prev,
                    {
                        from_id: user.chatify_auth_id,
                        message: messageToSend,
                        sent_date: new Date()
                    }
                ])
            }

            setMessage('');
            if (isSelectedZo) {

                const res = await axios.post(`${process.env.REACT_APP_AI_CHAT_URL}/api/app/mobile/send-chat-message`, {
                    message: messageToSend,
                }, { headers: { Authorization: `Bearer ${apiToken}` } })
                if (res.status === 200 && res.data.response !== null) {
                    setZoMsgCount(prev => prev + 1)
                    setIsZoError(false)
                    setZoLoading(false)
                    setZoTempMsgs(
                        prev => [
                            ...prev,
                            {
                                type: res.data.response.ai_response.type,
                                message: res.data.response.ai_response.message ?? 'Something went wrong with Zo.',
                                count: zoMsgCount
                            }
                        ])
                    setGame(res.data.response.gamification)
                    setIsAddXp(true)
                    track('sendMessage', { method: 'Success' })
                }
                else {
                    setZoLoading(false)
                    setIsZoError(true)
                    setZoTempMsgs(
                        prev => [
                            ...prev,
                            {
                                type: 'ai_message',
                                message: res.data.message.Info,
                                isError: true
                            }
                        ])
                }

            } else {
                const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/chatify/api/sendMessage`, {
                    message: messageToSend,
                    type: 'user',
                    temporaryMsgId: 'temp_1',
                    to_uuid: chatSelected?.user_id,
                    api_token: apiToken
                })
                if (res.status === 200) {
                    track('sendMessage', { method: 'Success' })
                    const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/post-chat-search-existing-chat`, {
                        api_token: apiToken
                    })
                    if (response.status === 200) {
                        setFilteredChat(response.data.records)
                    }
                }
            }
        } catch (error) {
            setZoLoading(false)
            setMessage('');
            setIsDisableSend(false)
            console.log(error)

        }

    }
    useEffect(() => {
        if (isAddXp) {
            setTimeout(() => {
                setIsAddXp(false)
            }, 3000);
        }
    }, [isAddXp])


    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };
    const handleMessage = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const lineCount = event.target.value.split('\n').length;
        setRows(lineCount > 3 ? 3 : lineCount);
        setMessage(event.target.value)
    }

    const fetchChatByPage = async (user_id: string, page: number, source: CancelTokenSource) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/chatify/api/fetchMessages`, {
                uuid: user_id,
                api_token: apiToken,
                page: page
            }, { cancelToken: source.token })
            if (res.status === 200) {
                setChatHistory((prev) => [...prev, res.data])
            }

        } catch (error) {

        }
    }
    const handleScrollChatBottom = () => {
        if (bottomChatRef.current) {
            bottomChatRef.current.scrollIntoView();
        }
    }
    useEffect(() => {
        if (bottomChatRef.current) {
            bottomChatRef.current.scrollIntoView();
            if (chatSelected && chatSelected.unread_message_count && chatSelected.unread_message_count > 0)
                seenMsg(chatSelected.user_id)
        }

        const handleScroll = () => {
            if (chatContainerRef.current && bottomChatRef.current && chatSelected && chatHistory) {
                const { scrollTop } = chatContainerRef.current;
                if (scrollTop === 0) {
                    setIsScrolledToTop(true);
                    if (chatPage < chatHistory[0].last_page) {
                        setChatPage((prev) => prev + 1)
                    } else {

                        setIsScrolledToTop(false)
                    }
                    // console.log('User has scrolled to the top');
                } else {
                    setIsScrolledToTop(false);
                }
            }
        };

        const chatContainer = chatContainerRef.current;
        chatContainer?.addEventListener('scroll', handleScroll);

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        // console.log('User scrolled away from the bottom chat');
                        setIsShowScrollDown(true)
                    } else {
                        setIsShowScrollDown(false)
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (bottomChatRef.current) {
            observer.observe(bottomChatRef.current);
        }

        return () => {
            if (bottomChatRef.current) {
                observer.unobserve(bottomChatRef.current);
            }
            chatContainer?.removeEventListener('scroll', handleScroll);

        };
    }, [chatHistory, chatSelected, tempMsgs, zoLoading, zoChat, zoTempMsgs]);

    useEffect(() => {
        track('screenView', { screen_name: 'ChatScreen' })
        let source = axios.CancelToken.source();
        if (chatSelected && chatPage) {
            fetchChatByPage(chatSelected.user_id, chatPage, source)
        }
        return () => {
            source.cancel();
        }
    }, [chatPage])

    useEffect(() => {
        // Initialize Pusher
        if (process.env.REACT_APP_PUSHER_CHANNELS_KEY && process.env.REACT_APP_PUSHER_CHATIFY_TOKEN) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_CHANNELS_KEY, {
                cluster: 'ap1',
                channelAuthorization: {
                    customHandler: async (payload, callback) => {
                        const { socketId, channelName } = payload;

                        try {
                            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/chat/auth`, {
                                socket_id: socketId,
                                channel_name: channelName,
                                api_token: apiToken,
                            }, {
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                },
                            })
                            callback(null, res.data);

                        } catch (error) {
                            console.log('Pusher channel auth error');

                            //   callback(error, null);
                        }

                    },
                },
            });
            const threadId = `${process.env.REACT_APP_PUSHER_CHATIFY_TOKEN}.${user.chatify_auth_id}`;
            const channel = pusher.subscribe(threadId);
            const messageCallback = async (data: any) => {
                // Handle new message event
                if (selectedChatRef.current?.id == data.from_id) {
                    setTempMsgs((prev) => [
                        ...prev,
                        {

                            from_id: data.from_id,
                            message: data.message.message,
                            sent_date: data.message.created_at
                        }
                    ])
                    updateIncomingMessages()
                }
                if (chatPersons.length < 1) {
                    const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/post-chat-search-existing-chat`, {
                        api_token: apiToken
                    })
                    if (res.status === 200) {
                        setChatPersons(res.data.records)
                        setFilteredChat(res.data.records)
                    }
                }

            };
            channel.bind('messaging', messageCallback)
            return (() => {
                pusher.unsubscribe(threadId)
            })
        }

    }, []);
    // Update the ref value whenever selectedChat changes
    useEffect(() => {
        if (chatSelected)
            selectedChatRef.current = chatSelected;
    }, [chatSelected]);

    useEffect(() => {
        const savedState = localStorage.getItem('chatState');
        if (savedState) {
            setIsSelectedZo(true)
            setFromVibeCheckState(JSON.parse(savedState));
            localStorage.removeItem('chatState'); // Clear state after retrieving
        }
        const stateFromQuest = localStorage.getItem('stateFromQuest');
        if (stateFromQuest) {
            setIsSelectedZo(true)
            localStorage.removeItem('stateFromQuest'); // Clear state after retrieving
        }
    }, []);

    useEffect(() => {

        const tier = localStorage.getItem('tier')
        if (tier && process.env.REACT_APP_TIER_SECRET) {
            try {
                const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
                const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
                setTierLists(tierData)
            } catch (error) {
            }
        }
    }, []);
    useEffect(() => {
        //Existing user
        if (tempMsgs.length > 0) {
            const checkSameDay = isSameDay(new Date(), tempMsgs[0].sent_date)
            if (!checkSameDay) {
                setIsShowDate(true)
            }
        }
    }, [tempMsgs])
    let lastDate: Date;

    // ----------------------------------- Zo Functions -----------------------------------

    function formatBoldText(text: string): Array<string | JSX.Element> {
        // Regex to find text between **
        const parts = text.split(/(\*\*[^*]+\*\*)/g);

        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return <strong key={index}> {part.slice(2, -2)} </strong>;
            }
            return part;
        });
    }
    const displayTextRenderer = (text: string) => {
        const parts = text.split(/(\*\*[^*]+\*\*)/g);

        return (
            <span>
                {parts.map((part, index) => {
                    if (part.startsWith('**') && part.endsWith('**')) {
                        return <strong key={index}>{part.slice(2, -2)}</strong>;
                    }
                    return <span key={index}>{part}</span>;
                })}
            </span>
        );
    };

    const fetchZoWelcomeChat = async (isCheckedIn: number = 0, isShowDate: boolean = false) => {
        try {
            setZoLoading(true)
            const res = await axios.get(`${initApi?.url.chat.get_welcome_message}?is_checkin=${isCheckedIn}`, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (res.status === 200) {
                setZoLoading(false)
                setZoTempMsgs(prev => [...prev, {
                    type: 'ai_message',
                    message: res.data.response.chat_message.message
                }])
                setIsShowDate(isShowDate);
                setIsLoading(false)
            }
        } catch (error) {
            setZoLoading(false)
            console.log("error", error)

        }
    }

    useEffect(() => {
        //Existing Zo user
        if (zoChat && zoChat[0] && zoChat.length > 0 && isSelectedZo) {
            const checkSameDay = isSameDay(new Date(), zoChat[0].date_sent)
            //To avoid call welcomeChat if from vibe check
            if (!checkSameDay && !fromVibeCheckState) {
                fetchZoWelcomeChat(0, !checkSameDay ? true : false)
            }
        }
        //First time chat with Zo
        else if (zoChat && zoChat.length < 1 && isSelectedZo) {
            fetchZoWelcomeChat(fromVibeCheckState ? fromVibeCheckState : 0, true)
        }
    }, [zoChat])
    useEffect(() => {
        //Called when user from vibe check
        if (fromVibeCheckState && isLoggedInAi && zoChat && zoChat.length > 0) {
            const checkSameDay = isSameDay(new Date(), zoChat[0].date_sent)

            fetchZoWelcomeChat(fromVibeCheckState, !checkSameDay ? true : false)
        }
    }, [isLoggedInAi, fromVibeCheckState, zoChat])

    // ----------------------------------- Gamification Functions -----------------------------------
    const { userProfile, setUserProfile } = useGameStore()
    const fetchUserProfile = async (source: CancelTokenSource) => {
        try {
            const res = await axios.post(`${initApi?.url.account.get_user_profile}`, {}, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token })
            if (res.status === 200) {
                setUserProfile(res.data.response.user)
            }

        } catch (error) {
            if (axios.CanceledError) {

            }
        }
    }
    useEffect(() => {
        if (game && game.quest_info) {
            if (game.quest_info.is_completed) {
                toast.info(<ChatGainXpPopUp game={game} />, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    icon: false
                });
            }
        }
        let source = axios.CancelToken.source()

        fetchUserProfile(source)
    }, [game])

    return (
        <div className='w-full h-screen flex pl-16 overflow-hidden relative'>
            {tierLists && <LeftPanel tierLists={tierLists} />}
            {isSelectedZo && showDisableZo ? <DisabledSection disabledModule={disabledRes.disable_module} /> :
                <section className='flex flex-col w-full h-full pt-8 relative'>
                    {isScrolledToTop && <div className='absolute flex w-full justify-center top-[10rem]'><FadeLoader className='absolute bottom-[-2rem] z-[9999]' /></div>}

                    <div className='flex gap-2 w-full min-h-[4rem] h-16 px-8 pb-4 border-b-2 relative'>
                        {isSelectedZo && !chatSelected ?
                            <div className='flex items-center gap-8 w-full'>
                                <div className='flex items-center gap-2'>
                                    <ZoIcon className='w-[50px] h-[50px]' />
                                    <p className='font-bold text-sm'>Zo</p>
                                </div>

                                {userProfile &&
                                    <div className='flex gap-4 justify-center items-center'>
                                        <p className='font-bold text-[#29B3E6]'>LV {userProfile?.gamification.current_level}</p>
                                        <div className="relative w-[200px] bg-gray-200 rounded-full dark:bg-gray-700">
                                            <div className="bg-[#29B3E6] text-xs font-medium text-center h-6 leading-none rounded-full" style={{ width: `${userProfile.gamification.current_xp * 100 / userProfile.gamification.xp_cap}%` }}></div>
                                            <div className='absolute transform translate-x-1/2 -translate-y-1/2 top-[50%] right-[50%] text-gray-500'>{userProfile.gamification.current_xp} / {userProfile.gamification.xp_cap}</div>
                                        </div>
                                    </div>
                                }
                                <FadeIn className='flex gap-4 items-center bg-[#ED3425] rounded-full px-4 py-1 ml-auto cursor-pointer'>
                                    <DangerIcon />
                                    <Link to='/i-need-help'><p className='text-white font-semibold text-lg'>I need help!</p></Link>
                                </FadeIn>
                            </div> :
                            chatSelected?.avatarURI ? <img src={chatSelected.avatarURI} alt="avatar" className='w-[50px] h-auto rounded-full' /> : <DefaultProfileIcon />}
                        <div className='flex flex-col'>
                            <p className='font-bold text-sm'>{chatSelected?.firstname}</p>
                            <p className='text-[#12143E] text-xs'>{chatSelected?.user_role}</p>
                        </div>
                    </div>
                    {isLoading ? <div className='h-screen flex items-center justify-center'><PulseLoader /> </div> : !chatSelected && !isSelectedZo ? <div className='h-screen flex items-center justify-center'><h1 className='mx-4 text-2xl'>Select a chat on the left to start chatting.</h1></div> : <div className='mt-auto pt-8 pb-8 px-12 overflow-y-auto ' ref={chatContainerRef}>

                        {chatHistory && chatHistory.length > 0 && user && [...chatHistory].reverse().map((chat) => {
                            return ([...chat.messages].reverse().map((msg, index) => {
                                const chatDate = new Date(msg.created_at);
                                const showDate = !lastDate || !isSameDay(lastDate, chatDate);
                                if (showDate) {
                                    lastDate = chatDate;
                                }
                                return (
                                    <Fragment key={index}>
                                        {/* Receiver */}
                                        {showDate &&
                                            <div className='flex items-center mb-4'>
                                                <hr className='w-full flex-[3]' />
                                                <span className='w-full text-center flex-1'>{formatDate(chatDate.toDateString())}</span>
                                                <hr className='w-full flex-[3]' />
                                            </div>}
                                        {user.uuid !== msg.from_uuid && (
                                            <div className='flex gap-4 mb-4 items-end'>
                                                {chatSelected?.avatarURI ? <img src={chatSelected.avatarURI} alt="avatar" className='h-[50px] rounded-full' /> : <DefaultProfileIcon className='self-end' />}
                                                <div className='relative bg-[#29B3E6] min-w-[7rem] max-w-[50%] min-h-24 rounded-r-2xl rounded-tl-2xl'>
                                                    <p className='p-4 text-white mb-8'>{msg.body}</p>
                                                    <p className='absolute bottom-2 right-2 text-white'>{formatTime(new Date(msg.created_at))}</p>
                                                </div>
                                            </div>
                                        )}
                                        {/* Sender */}
                                        {user.uuid === msg.from_uuid && (
                                            <div className='flex flex-row-reverse mb-4'>
                                                <div className='relative bg-[#12133D] min-w-[7rem] max-w-[50%] min-h-24 rounded-l-2xl rounded-tr-2xl '>
                                                    <p className='p-4 text-white mb-8'>{msg.body}</p>
                                                    <p className='absolute bottom-2 right-8 text-white'>{formatTime(new Date(msg.created_at))}</p>
                                                    {msg.seen ? <SeenBlueIcon className='absolute bottom-2 right-2' /> : <SentWhiteIcon className='absolute bottom-2 right-2' />}
                                                </div>
                                            </div>
                                        )}

                                    </Fragment>
                                )
                            })
                            )
                        })}
                        {zoChat && zoChat.length > 0 && isSelectedZo && !chatSelected && user && [...zoChat].reverse().map((chat) => {

                            const chatDate = new Date(chat.date_sent);
                            const showDate = !lastDate || !isSameDay(lastDate, chatDate);
                            if (showDate) {
                                lastDate = chatDate;
                            }
                            return (
                                <Fragment key={chat.msg_uuid}>
                                    {/* Receiver */}
                                    {showDate &&
                                        <div className='flex items-center mb-4'>
                                            <hr className='w-full flex-[3]' />
                                            <span className='w-full text-center flex-1'>{formatDate(chatDate.toDateString())}</span>
                                            <hr className='w-full flex-[3]' />
                                        </div>}
                                    {chat.type === 'ai_message' && (
                                        <div className='flex gap-4 mb-4 items-end'>
                                            <ZoIcon className='self-end' />
                                            <div className='relative bg-[#29B3E6] w-1/2 xl:w-1/3 min-h-24 rounded-r-2xl rounded-tl-2xl'>
                                                <p className='p-4 text-white'>{formatBoldText(chat.message)}</p>
                                                {/* <p className='absolute bottom-2 right-2 text-white'>{formatTime(new Date(chat.date_sent))}</p> */}
                                            </div>
                                        </div>
                                    )}


                                    {/* Sender */}
                                    {user.uuid === chat.from_user?.uuid && (
                                        <div className='flex flex-row-reverse mb-4'>
                                            <div className='relative bg-[#12133D] min-w-[7rem] max-w-[50%] min-h-24 rounded-l-2xl rounded-tr-2xl '>
                                                <p className='p-4 text-white'>{chat.message}</p>
                                                {/* <p className='absolute bottom-2 right-8 text-white'>{formatTime(new Date(chat.date_sent))}</p> */}
                                            </div>
                                        </div>
                                    )}

                                </Fragment>
                            )

                        })}
                        {tempMsgs.length > 0 && tempMsgs.map((msg, index) =>
                            msg.from_id !== user.chatify_auth_id ?
                                (
                                    <div key={index} className='flex gap-4 mb-4 items-end'>
                                        {chatSelected?.avatarURI ? <img src={chatSelected.avatarURI} alt="avatar" className='h-[50px] rounded-full' /> : <DefaultProfileIcon className='self-end' />}
                                        <div className='relative bg-[#29B3E6] min-w-[7rem] max-w-[50%] min-h-24 rounded-r-2xl rounded-tl-2xl'>
                                            <p className='p-4 text-white mb-8'>{msg.message}</p>
                                            <p className='absolute bottom-2 right-2 text-white'>{(formatTime(msg.sent_date))}</p>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className='flex flex-row-reverse mb-4'>
                                        <div className='relative bg-[#12133D] min-w-[7rem] max-w-[50%] min-h-24 rounded-l-2xl rounded-tr-2xl '>
                                            <p className='p-4 text-white mb-8'>{msg.message}</p>
                                            <p className='absolute bottom-2 right-8 text-white'>{(formatTime(new Date()))}</p>
                                            {/* {msg.seen ? <SeenBlueIcon className='absolute bottom-2 right-2' /> : <SentWhiteIcon className='absolute bottom-2 right-2' />} */}
                                            <SentWhiteIcon className='absolute bottom-2 right-2' />
                                        </div>
                                    </div>
                                )
                        )}
                        {isShowDate &&
                            <div className='flex items-center mb-4'>
                                <hr className='w-full flex-[3]' />
                                <span className='w-full text-center flex-1'>{formatDate(new Date().toDateString())}</span>
                                <hr className='w-full flex-[3]' />
                            </div>}
                        {zoTempMsgs.length > 0 && !chatSelected &&
                            zoTempMsgs.map((msg, index) =>
                                msg.type !== 'user_message' ?
                                    <div className='flex gap-4 mb-4 items-end' key={index}>
                                        <ZoIcon className='self-end' />
                                        <div className={`relative ${isZoError && msg.isError ? 'bg-[#fee3ec] text-black' : 'bg-[#29B3E6] text-white'} min-w-[7rem] max-w-[50%] min-h-24 rounded-r-2xl rounded-tl-2xl`}>
                                            <div className='p-4'>

                                                {/* <TypeAnimation
                                                    sequence={[
                                                         `${msg.message}`,
                                                        1000,
                                                        () => setCursorTyping(false)
                                                    ]}
                                                    speed={50}
                                                    cursor={false}
                                                /> */}
                                                {index === zoTempMsgs.length - 1 ? ( // Check if it's the last message
                                                    <ReactTypingEffect
                                                        className='zoCursor'
                                                        text={msg.message}
                                                        displayTextRenderer={displayTextRenderer}
                                                        cursor=""
                                                        typingDelay={0}
                                                        speed={50}
                                                        eraseDelay={100000000}
                                                        eraseSpeed={0}
                                                    />
                                                ) : (
                                                    displayTextRenderer(msg.message) // Render normal text for others
                                                )}
                                            </div>
                                            {/* <p className='absolute bottom-2 right-2 text-white'>{new Date(zoRes.ai_response.date_sent).toLocaleTimeString()}</p> */}
                                        </div>
                                    </div>
                                    :
                                    (
                                        <div className='flex flex-row-reverse mb-4' key={index}>
                                            {/* Sender */}
                                            <div className='relative bg-[#12133D] min-w-[7rem] max-w-[50%] min-h-24 rounded-l-2xl rounded-tr-2xl'>
                                                <p className='p-4 text-white'>{msg.message}</p>
                                                {/* <p className='absolute bottom-2 right-8 text-white'>{new Date().toLocaleTimeString()}</p> */}

                                                {game?.xp_earned && msg.count === zoMsgCount &&
                                                    <>
                                                        <div className={`absolute left-[-72px] top-[-20px] ${isAddXp ? 'block' : 'hidden'}`}>
                                                            <Lottie
                                                                options={defaultOptions}
                                                                height={100}
                                                                width={100}
                                                            />
                                                        </div>
                                                        <Transition
                                                            show={isAddXp}
                                                            enter="transition-opacity duration-75"
                                                            enterFrom="opacity-0"
                                                            enterTo="opacity-100"
                                                            leave="transition-opacity duration-150"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        ><span className='absolute left-[-2rem] top-4 text-[#29B3E6]'>+{game.xp_earned}</span></Transition>
                                                    </>}
                                            </div>
                                        </div>
                                    )
                            )}
                        {zoLoading && !chatSelected &&
                            <div className='flex gap-4 mb-4 items-center'>
                                <ZoIcon className='self-end' /><SyncLoader />
                            </div>}

                        <div ref={bottomChatRef}></div>


                    </div>
                    }
                    {(chatSelected || isSelectedZo) && user && <><hr className='mt-8' />
                        <div className='flex gap-4 items-center mx-12 relative'>
                            {/* <input type="text" name="input-chat" value={message} placeholder='Type your message....' className=' bg-[#EAE9E9] w-full border rounded-full my-8 p-4' onChange={handleMessage} onKeyDown={message ? handleKeyDown : () => null} /> */}
                            <div className='bg-[#EAE9E9] w-full border rounded-full my-8 p-4'>
                                <textarea maxLength={500} rows={rows} className='bg-[#EAE9E9] w-full border outline-none resize-none' name="input-chat" value={message} placeholder='Type your message....' onChange={handleMessage} onKeyDown={message && !isDisableSend ? handleKeyDown : () => null} />
                            </div>
                            {<SendIcon color={isDisableSend ? '#EAE9E9' : '#26B8EB'} className={`${message && !isDisableSend ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={message && !isDisableSend ? sendMessage : () => null} />}

                            {isShowScrollDown ? <ScrollDownIcon className='absolute cursor-pointer flex justify-center top-[-3rem] left-0 w-full z-[9999]' onClick={handleScrollChatBottom} /> : isSelectedZo && showDisableZo ? "" : ""}
                        </div>
                    </>
                    }

                </section>}
            {game && <QuestProgressPopUp gamification={game} isOpen={isOpen} close={() => setIsOpen(false)} />}

            {!isLoading && !tierLists?.chat_1_1 && !tierLists?.chat_ai && <Blur><div className='flex gap-4 items-center'><p>Coming Soon</p><img src={cone} /></div></Blur>}

        </div>
    )
}
