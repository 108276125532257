import React, { useEffect } from "react";
import underMaintenance from '../asset/maintenance/under-maintenance.png'
import { formatMaintenanceDate } from "../helpers/maintenenceDateTime";
import { useNavigate } from "react-router-dom";
import { useSystemStore } from "../store/system";

interface Maintenance {
    startDate: string;
    endDate: string;
}
export default function Maintenance({ startDate, endDate }: Maintenance) {
    const navigate = useNavigate();
    const { isUnderMaintenance } = useSystemStore()

    useEffect(() => {
        if (!isUnderMaintenance)
            navigate('/home', { replace: true }); // Redirect to the home page
    }, [])

    return <div
        className='flex justify-center items-center flex-col h-screen overflow-hidden'
        style={{
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}
    >
        <div className='flex flex-col gap-16 justify-center items-center h-screen w-screen pb-10 relative'>
            <img src={underMaintenance} alt="" className="w-1/4" />
            <div className="flex flex-col gap-4 w-1/3">
                <div className="flex gap-4 items-center text-center">
                    <span className="flex-[2] bg-[#fde5bb] p-3 rounded-xl">Starting time</span>
                    {startDate ? <span className="flex-[3]">{formatMaintenanceDate(startDate)} (UTC)</span> : <span className="flex-[3]"></span>}
                </div>
                <div className="flex gap-4 items-center text-center">
                    <span className="flex-[2] bg-[#fde5bb] p-3 rounded-xl">Ending time</span>
                    {endDate ? <span className="flex-[3]">{formatMaintenanceDate(endDate)} (UTC)</span> : <span className="flex-[3]"></span>}
                </div>
            </div>
        </div>
    </div>
}
