import React from "react";
import { Outlet } from "react-router-dom";
import { useAuthStore } from "../store/auth";

export default function LoginRoute() {
    const apiToken = sessionStorage.getItem('api_token');
    const setAuth = useAuthStore((state) => state.setAuth);
    if (apiToken) {
        try {
            setAuth(true);
        } catch (error) {
            setAuth(false);
            return <Outlet />;
        }
    }
    return <Outlet />;
};