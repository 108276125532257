import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import JournalWidget from '../components/JournalWidget';
import SidePanel from '../components/SidePanel';
import axios, { CancelTokenSource } from 'axios';
import "react-toastify/dist/ReactToastify.css";
import CarouselWidget from '../components/CarouselWidget';
import { PulseLoader } from 'react-spinners';
import { useTierStore } from '../store/tier';
import Error from '../components/Error';
import Blur from '../components/Blur';
import { TierLists } from '../types/tier';
import SurveyBanner from '../components/SurveyBanner';
import DangerIcon from '../asset/ineedhelp/DangerIcon';
import { UserDataProps } from '../types/common';
import { encryptData } from '../helpers/encryptData';
import FadeIn from '../animations/FadeIn';
import Quest from '../components/Quest';
import StreaksPopUp from '../components/gamification/StreaksPopUp';
import OnBoarding from '../components/setting/OnBoarding';
import { useInitApi } from '../store/initApi';
import { add, init, track } from '@amplitude/analytics-browser';
import { ampli, Environment, EventOptions } from '../ampli';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import upcomingMaintenanceBanner from '../asset/maintenance/large-upcoming-maintenance.png'
import { useSystemStore } from '../store/system';
import {diffHours, formatMaintenanceDate} from '../helpers/maintenenceDateTime'
interface Prompt {
  id: string;
  title_id: string;
  literature: string;
  end_encouragement_text: string;
  daily_submitted: number;
}

interface HighlightItem {
  url: string;
  image: string;
  title: string;
  description: string;
}
interface CustomUserTraits extends EventOptions {
  userProp?: string;
}
type AmplitudeEnvironment = 'zoalastaging' | 'production' | 'development'; // Add more environments as needed

function Home() {
  const navigate = useNavigate();
  const shownBoardingPrivacy = localStorage.getItem('shownBoardingPrivacy')
  const [userData, setUserData] = useState<UserDataProps>();
  const [prompt, setPrompt] = useState<Prompt>();
  const [highlight, setHighlight] = useState<HighlightItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tierLists, setTierLists] = useState<TierLists>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOnBoarding, setIsOnBoarding] = useState(false)

  const [game, setGame] = useState<QuestProps>()
  const setTier = useTierStore((state) => state.setTier)
  const initApi = useInitApi(state => state.initApi)
  const { isUpcomingMaintenance, maintenanceStart, maintenanceEnd } = useSystemStore()
  const apiToken = sessionStorage.getItem('api_token');


  useEffect(() => {
    const storedUserData = sessionStorage.getItem('user');
    if (apiToken && storedUserData) {
      setUserData(JSON.parse(storedUserData));
      // fetchApi(apiToken)
      if (process.env.REACT_APP_AMPLITUDE_ENV) {
        const amplitudeEnv = (process.env.REACT_APP_AMPLITUDE_ENV as Environment) || 'zoalastaging';

        ampli.load({ environment: 'production' });
      } else {
        // If no environment variable is set, default to 'zoalastaging'
        ampli.load({ environment: 'zoalastaging' });
      }
      ampli.identify('-', {
        orgId: JSON.parse(storedUserData).current_org_id ?? "", // Custom user property
        orgRole: JSON.parse(storedUserData).org_roles[0].role ?? "",
      } as CustomUserTraits);
      // Create and Install Session Replay Plugin
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: process.env.REACT_APP_SESSION_REPLAY_SAMPLE_RATE ? parseInt(process.env.REACT_APP_SESSION_REPLAY_SAMPLE_RATE) : 1
      });
      add(sessionReplayTracking);

      init(process.env.REACT_APP_AMPLITUDE_API_KEY!, {
        autocapture: {
          pageViews: false,
          elementInteractions: false,
          sessions: process.env.REACT_APP_SESSION_REPLAY_TRACKING === 'true' ? true : false,
        }

      });
      track('screenView', { screen_name: 'HomeScreen' })

    } else {
      // navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchHome = async (source: CancelTokenSource) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/home`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
        if (response.status === 200) {
          setPrompt(response.data.response.prompt_section);
          setHighlight(response.data.response.highlight_section);
          setTierLists(response.data.response.feature_control)
          setLoading(false);
        } else {
          navigate('/login');
        }
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          navigate('/login');
        }
      }
    }
    let source = axios.CancelToken.source();
    fetchHome(source);
    return () => {
      source.cancel();
    }
  }, [])

  const encryptTier = (tierData: TierLists) => {
    if (process.env.REACT_APP_TIER_SECRET) {
      try {
        const encryptedData = encryptData({ data: JSON.stringify(tierData), secret: process.env.REACT_APP_TIER_SECRET });
        localStorage.setItem("tier", encryptedData)
        return encryptedData;

      } catch (error) {
      }
    }
    return;
  }


  useEffect(() => {
    if (tierLists) {
      const encryptedData = encryptTier(tierLists)
      if (encryptedData) {
        setTier(encryptedData)
      }
    }
  }, [tierLists])

  useEffect(() => {
    if (apiToken && initApi && initApi.user.user_info.no_pin_record === 1) {
      setIsOnBoarding(true)
    }
  }, [])


  const handleOpenStreak = (value: boolean) => {
    setIsOpen(value)
  }
  const handlePassQuest = (value: QuestProps) => {
    setGame(value)
  }

  return (
    <div className='h-screen w-screen'>
      <div className='pl-20 flex w-full h-full p-5'>
        <section className='bg-white h-full w-[70%] md:w-[70%] xl:w-[75%] px-8 flex flex-col items-center'>
          {userData?.org_roles[0].role === 'Student' ?
            <FadeIn className='flex gap-4 self-end items-center bg-[#ED3425] rounded-full px-4 py-1 cursor-pointer'>
              <DangerIcon />
              <Link to='/i-need-help'><p className='text-white font-semibold text-lg'>I need help!</p></Link>
            </FadeIn> : ""}
          {isUpcomingMaintenance ?
            <div className='w-1/2 relative my-8'>
              <img src={upcomingMaintenanceBanner} className='rounded-3xl' />
              <div className="flex justify-between gap-4 absolute right-4 bottom-2">
                <span className='bg-[#ffffff94] py-2 px-4 rounded-3xl'>{formatMaintenanceDate(maintenanceStart)} (UTC)</span>
                <span className='bg-[#ffffff94] py-2 px-4 rounded-3xl'>{diffHours(maintenanceStart, maintenanceEnd)}</span>
              </div>
            </div> : ""}
          {loading ? (
            <div className='w-full h-full flex items-center justify-center'>
              <PulseLoader />
            </div>
          ) : prompt && apiToken ? (
            <>
              <FadeIn>
                <JournalWidget prompt={prompt} apiToken={apiToken}>
                  {tierLists?.journal ? <></> : <Blur />}
                </JournalWidget>
              </FadeIn>
              <CarouselWidget highlight={highlight}>
                {tierLists?.highlights ? <></> : <Blur />}
              </CarouselWidget>
              <SurveyBanner />
              <Quest handlePassQuest={handlePassQuest} handleOpenStreak={handleOpenStreak} />
              {game && <StreaksPopUp gamification={game} isOpen={isOpen} close={() => setIsOpen(false)} />}

              <div className='w-full h-12'></div>
            </>
          ) : <div className='w-full h-full flex items-center justify-center gap-4'>
            <Error mainText="Prompt Section Not Found" />
          </div>}
        </section>

        <aside className='bg-white h-auto w-[25%] sm:w-[30%] xl:w-[25%]'>
          {userData && tierLists ? <SidePanel userData={userData} tierLists={tierLists} /> : ""}
        </aside>
        {!shownBoardingPrivacy && apiToken && initApi && initApi.user.user_info.no_pin_record === 1 ? <OnBoarding isOnBoarding={isOnBoarding} close={() => setIsOnBoarding(false)} /> : ""}

      </div>
    </div>
  )
}

export default Home;
