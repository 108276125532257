import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSystemStore } from '../store/system';

const MaintenanceChecker = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const { setMaintenanceStart, setMaintenanceEnd, setIsUnderMaintenance, setIsUpcomingMaintenance } = useSystemStore()

    useEffect(() => {
        if (process.env.REACT_APP_MAINTENENCE_MODE !== 'on') return;
        const checkMaintenance = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/system/maintenance-info`)
                if (res.status === 200) {
                    setIsUpcomingMaintenance(res.data.scheduled_maintenance)
                    setIsUnderMaintenance(res.data.under_maintenance)
                    if (res.data.maintenance_period) {
                        setMaintenanceStart(res.data.maintenance_period.start)
                        setMaintenanceEnd(res.data.maintenance_period.end)
                    }
                    if (res.data.under_maintenance) {
                        navigate('/maintenance', { replace: true }); // Redirect to the maintenance page
                    }
                }
            } catch (error) {
                console.error('Error checking maintenance status:', error);
            }
        };

        checkMaintenance();
    }, [navigate]);

    return <>{children}</>;
};

export default MaintenanceChecker;
