function diffHours(startDate: string, endDate: string) {
    // Replace space with 'T' for ISO format
    const start = new Date(startDate.replace(' ', 'T'));
    const end = new Date(endDate.replace(' ', 'T'));

    // Ensure both dates are valid
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        throw new Error('Invalid date format');
    }

    // Calculate the difference in milliseconds
    const diffMs = end.getTime() - start.getTime();

    // Convert milliseconds to hours
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // 1 hour = 3600000 ms

    const diffHoursText = `${diffHours} hrs`;

    return diffHoursText;
}

function formatMaintenanceDate(startDate: string) {
    // Convert to Date object
    const dateObj = new Date(startDate);

    // Format the date
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' }); // Dec
    const year = dateObj.getFullYear().toString().slice(-2); // 24
    const time = dateObj.toLocaleTimeString('en-US'); // 4:00:00 PM

    const formattedDate = `${day} ${month} ${year}, ${time}`;

    return formattedDate;
}

export {diffHours, formatMaintenanceDate}