import React, { useEffect, useState } from 'react'
import CloseNewChatIcon from '../../asset/chat/CloseNewChatIcon'
import SearchBar from './Search'
import { TierLists } from '../../types/tier'
import axios, { CancelTokenSource } from 'axios'
import {useHomeStore } from '../../store/home'
import ZoIcon from '../../asset/chat/ZoIcon'
import NameTooltip from '../NameTooltip'
import { calculateTimeDifference } from '../../helpers/dateTime'
import { PulseLoader } from 'react-spinners'
import betaActive from '../../asset/chat/beta-active.png'
import DefaultProfileIcon from '../../asset/chat/DefaultProfileIcon'
import AddIcon from '../../asset/chat/AddIcon'
import { UserDataProps } from '../../types/common'
import { useInitApi } from '../../store/initApi'
import { useChatStore } from '../../store/chat'
import { track } from '@amplitude/analytics-browser';

interface ChatLeftPanel {
    tierLists: TierLists
}
export default function LeftPanel({ tierLists }: ChatLeftPanel) {
    const apiToken = sessionStorage.getItem('api_token')
    const storedUserData = sessionStorage.getItem('user')
    const user: UserDataProps = storedUserData ? JSON.parse(storedUserData) : {}

    const [isContactLoading, setIsContactLoading] = useState(true)
    const [disableChatSelect, setDisableChatSelect] = useState(false)
    const initApi = useInitApi((state) => state.initApi)

    const disabledRes = useHomeStore((state) => state.home)

    const { setChatHistory, filteredChat, setFilteredChat, chatSelected, setChatSelected, isSelectedZo, setIsSelectedZo, isLoggedInAi, setIsLoggedInAi, setShowDisableZo, setZoTempMsgs, zoChat, setZoChat, setTempMsgs, setFromVibeCheckState, setIsLoading, chatPage, setChatPage, isNewChat, setIsNewChat, chatPersons, setChatPersons } = useChatStore()

    const handleNewChat = (value: boolean) => {
        setIsNewChat(value)
    }
    const handleChatSelected = (user_id: string, person: ChatPersonProps) => {
        track('screenView', { screen_name: 'Counsellor&StudentChatScreen' })
        if (chatSelected?.user_id === user_id) {
            return;
        }
        let source = axios.CancelToken.source();
        fetchChats(user_id, source)
        setChatPage(prev => 1)
        setChatSelected(person)
    }
    const handleSelectedZo = () => {
        track('screenView', { screen_name: 'ZoChatScreen' })
        setChatSelected(null)
        if (isSelectedZo) {
            return;
        }
        if (disabledRes.disable_module === 'chat_ai') {
            setShowDisableZo(true)
        } else {
            fetchZoMsg()
        }
        setIsSelectedZo(true)
        setZoTempMsgs(prev => [])
    }
    const seenMsg = async (from_uuid: string) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/chatify/api/makeSeen`, {
                from_uuid: from_uuid,
                api_token: apiToken
            })
            if (res.status === 200) {
                const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/post-chat-search-existing-chat`, {
                    api_token: apiToken
                })
                if (response.status === 200) {
                    setChatPersons(response.data.records)
                    setFilteredChat(response.data.records)
                }
            }

        } catch (error) {
        }
    }
    const loginAi = async (source: CancelTokenSource) => {
        setIsLoading(true)
        try {
            const res = await axios.post(`${process.env.REACT_APP_AI_CHAT_URL}/api/app/mobile/login/v2`, {
                api_token: apiToken,
                uuid: user.uuid
            }, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token })
            if (res.status === 200) {
                setIsLoggedInAi(res.data.response)
                setIsLoading(false)
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                setIsLoading(false)
            }
        }
    }
    const fetchZoMsg = async (init: boolean = false) => {
        if (init) {
            setIsContactLoading(true)
        }
        setIsLoading(true)
        try {
            const res = await axios.get(`${initApi?.url.chat.get_chat_messages}`, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (res.status === 200) {
                setZoChat(res.data.response)
                setIsContactLoading(false)
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
            setIsContactLoading(false)
            setIsLoading(false)
        }
    }
    const fetchChats = async (user_id: string, source: CancelTokenSource) => {
        setIsLoading(true)

        try {
            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/chatify/api/fetchMessages`, {
                uuid: user_id,
                api_token: apiToken
            }, { cancelToken: source.token })
            setIsLoading(false)
            setDisableChatSelect(false)
            if (res.data.messages.length > 1) {
                seenMsg(user_id)
            }
            setChatHistory(prev => [res.data])
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                setDisableChatSelect(false)
                // setIsLoading(false)
            }

        }
    }
    const fetchNewContact = async (source: CancelTokenSource) => {
        try {
            setIsContactLoading(true)
            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/post-chat-search-new-chat`, {
                api_token: apiToken
            }, { cancelToken: source.token })
            if (res.status === 200) {
                setIsContactLoading(false)
                setChatPersons(res.data.records)
                setFilteredChat(res.data.records)
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                console.log(error)
                // setIsLoading(false)
                setIsContactLoading(false)
            }
        }
    }
    const fetchContacts = async (source: CancelTokenSource) => {
        try {
            setIsContactLoading(true)
            const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/post-chat-search-existing-chat`, {
                api_token: apiToken
            }, { cancelToken: source.token })
            if (res.status === 200) {
                setChatPersons(res.data.records)
                setFilteredChat(res.data.records)
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                setIsContactLoading(false)
            }
        }
    }
    useEffect(() => {
        if (filteredChat) {
            setIsContactLoading(false)
        }
    }, [filteredChat])
    useEffect(() => {
        if (isNewChat) {
            let source = axios.CancelToken.source();
            fetchNewContact(source)
            return () => {
                source.cancel();
            }
        } else {
            let source = axios.CancelToken.source();
            fetchContacts(source)
            return () => {
                source.cancel();
            }
        }
    }, [isNewChat])
    useEffect(() => {
        if (isLoggedInAi && !zoChat )
            fetchZoMsg(true)

    }, [isLoggedInAi])
    useEffect(() => {
        let source = axios.CancelToken.source();
        if (!isLoggedInAi) {
            loginAi(source)
        }
        return () => {
            source.cancel();
        }
    }, [])
    useEffect(() => {
        if (chatSelected || isSelectedZo) {
            setTempMsgs(prev => [])
        }
        if (chatSelected) {
            setDisableChatSelect(true)
            setIsSelectedZo(false)
            setFromVibeCheckState(null)
            setZoTempMsgs(prev => [])
        }
    }, [chatSelected, isSelectedZo])
    useEffect(() => {
        if (filteredChat && zoChat) {
            setIsContactLoading(false)
        }
    }, [filteredChat, zoChat])
    return (
        <aside className='w-2/5 h-full border-r-2 pt-8'>
            <div className='flex items-center justify-between h-16 px-8 pb-4 border-b-2'>
                <h5 className='font-bold text-xl'>Chats</h5>
                {isNewChat && tierLists?.chat_1_1 ? <CloseNewChatIcon className='cursor-pointer' onClick={() => handleNewChat(!isNewChat)} /> : tierLists?.chat_1_1 ? <AddIcon onClick={() => handleNewChat(!isNewChat)} /> : ""}
            </div>
            <section className='h-[calc(100%-10rem)]'>
                <SearchBar
                    chatPersons={chatPersons}
                    onSearchResults={(results) => setFilteredChat(results)}
                />
                <div className='h-full overflow-auto px-8'>
                    {isNewChat ? <></> : !isContactLoading && <div className={`${isSelectedZo ? 'bg-[#161D46]' : 'bg-[#26B8EB]'} h-[100px] rounded-2xl text-white p-4 mt-8 cursor-pointer`} onClick={handleSelectedZo}>
                        <div className='flex justify-between items-center'>
                            <ZoIcon className='flex-1' />
                            <div className='flex flex-col flex-[2] ml-4 max-w-[80px]'>
                                <p className='font-bold'>Zo</p>
                                <p className='text-white text-sm leading-4 text-ellipsis whitespace-nowrap overflow-hidden'>{zoChat && zoChat[0] && zoChat[0].message}</p>
                            </div>
                            <p className='flex-1 self-start text-xs text-center'>{zoChat && zoChat[0] && zoChat[0].date_sent ? calculateTimeDifference(zoChat[0].date_sent.toString()) : ""}</p>
                        </div>
                    </div>
                    }



                    {isContactLoading ? <div className='flex justify-center w-full mt-8'><PulseLoader /></div> :
                        tierLists?.chat_1_1 && filteredChat.length > 0 ? filteredChat.map((person) => {
                            return (
                                <div key={person.id} className={`flex items-center gap-4 h-[100px] border border-[#E9E9E9] rounded-2xl p-4 mt-4 cursor-pointer ${chatSelected?.user_id === person.user_id && "bg-[#161d46] text-white"}`} onClick={!disableChatSelect ? () => handleChatSelected(person.user_id, person) : () => null}>
                                    <div className='flex w-full min-w-0 flex-col gap-2 items-center relative'>
                                        {person?.avatarURI ? <img src={person.avatarURI} alt="avatar" className='w-[60px] h-auto rounded-full' /> : <DefaultProfileIcon />}
                                        <p className={`${chatSelected?.user_id === person.user_id ? "text-white" : "text-[#12143E]"}  text-xs`}>{person.user_role}</p>
                                        {process.env.REACT_APP_CHAT_BETA ? <img src={betaActive} className="absolute top-0 right-0" width={50} height={50} /> : ""}
                                    </div>
                                    <div className='flex max-w-[80px] flex-col'>
                                        <a
                                            data-tooltip-id="chat-user-name-tooltip"
                                            data-tooltip-place="top"
                                            data-tooltip-html={`${person.firstname} ${person.lastname}`}
                                        >
                                            <p className='h-[60px] font-bold xl:text-xl overflow-hidden text-ellipsis'>{person.firstname} {person.lastname}</p>

                                        </a>
                                        <NameTooltip />
                                        <p className={`${chatSelected?.user_id === person.user_id ? "text-white" : person.unread_message_count && person.unread_message_count > 0 ? 'text-[#5B5B5B] font-semibold' : 'text-[#5B5B5B]'} text-sm leading-4 text-ellipsis whitespace-nowrap overflow-hidden`}>{person.last_sent_message}</p>
                                        {/* <p className={`${counsellor.seen ? 'text-[#8F8E8E] ' : 'text-[#5B5B5B] font-semibold'} text-sm leading-4`}>{chatList.chat_history}</p> */}
                                    </div>
                                    <div className='flex w-full flex-col self-start gap-2 items-center'>
                                        <p className='text-xs'>{person.last_sent_message_date ? calculateTimeDifference(person.last_sent_message_date) : ""}</p>
                                        {person.unread_message_count ? <div className=''><div className='bg-[#29B3E6] w-6 h-6 rounded-full text-white text-center'>{person.unread_message_count}</div></div> : <></>}
                                    </div>
                                </div>
                            )
                        }) : ""}
                </div>
            </section>
        </aside>
    )
}
