import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthStore } from "../store/auth";
import { useHomeStore } from "../store/home";
import { usePrivacyStore } from "../store/privacy";
import { useInitApi } from "../store/initApi";

export interface InitApiResponse {
    gender: string;
    privacy_mode: boolean;
    auto_lock_duration: number;
    no_pin_record: number;
}

export interface UseAppInitializationProps {
    apiToken: string | null;
}

export default function useAppInitialization(apiToken: string | null) {
    const [loading, setLoading] = useState(true); // 1. Initialize loading state
    const { setGenderPage } = useAuthStore();
    const setHome = useHomeStore((state) => state.setHome);
    const setInitApi = useInitApi((state) => state.setInitApi);
    const setIsPrivacy = usePrivacyStore(state => state.setIsPrivacy)
    const setAutoLockDuration = usePrivacyStore(state => state.setAutoLockDuration)
    const { setIsShowLocked } = usePrivacyStore()

    const fetchApi = async () => {
        try {
            const fromLogin = localStorage.getItem("from_login");
            const response = await axios.post(
                `${process.env.REACT_APP_ENV_URL}/api/app/mobile/init`,
                {},
                { headers: { Authorization: `Bearer ${apiToken}` } }
            );

            if (response.status === 200 && process.env.REACT_APP_ENCRYPT_SECRET) {
                const userInfo = response.data.response.user.user_info;

                setGenderPage(userInfo.gender === "");
                setIsPrivacy(userInfo.privacy_mode);
                setAutoLockDuration(userInfo.auto_lock_duration);

                if (
                    userInfo.privacy_mode === 1 &&
                    userInfo.no_pin_record === 0 &&
                    userInfo.auto_lock_duration === 0 &&
                    !fromLogin
                ) {
                    setIsShowLocked(true);
                }

                localStorage.removeItem("from_login");
                setInitApi(response.data.response);
            }
        } catch (error) {
            console.error("Error in fetchApi:", error);
        }
    };

    const fetchHome = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/home`, {
                headers: { Authorization: `Bearer ${apiToken}` },
            });

            if (response.status === 200) {
                setHome(response.data.response.disable_section);
            }
        } catch (error) {
            console.error("Error in fetchHome:", error);
        }
    };

    useEffect(() => {
        if (apiToken) {
            setLoading(true);
            Promise.all([fetchApi(), fetchHome()])
                .catch((error) => console.error("Error fetching data:", error))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [apiToken]);

    return {
        loading
    };
}
