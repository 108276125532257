import { create } from 'zustand'

interface SystemStore {
    isUpcomingMaintenance: boolean
    setIsUpcomingMaintenance: (value: boolean) => void
    isUnderMaintenance: boolean
    setIsUnderMaintenance: (value: boolean) => void
    maintenanceStart: string
    setMaintenanceStart: (value: string) => void
    maintenanceEnd: string
    setMaintenanceEnd: (value: string) => void
}

export const useSystemStore = create<SystemStore>((set) => ({
    isUpcomingMaintenance: false,
    setIsUpcomingMaintenance: (newValue) => set(() => ({ isUpcomingMaintenance: newValue })),
    isUnderMaintenance: false,
    setIsUnderMaintenance: (newValue) => set(() => ({ isUnderMaintenance: newValue })),
    maintenanceStart: "",
    setMaintenanceStart: (newValue) => set(() => ({ maintenanceStart: newValue })),
    maintenanceEnd: "",
    setMaintenanceEnd: (newValue) => set(() => ({ maintenanceEnd: newValue })),
}))
