import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { PlayerIcon } from './PlayerIcon';
import unmute from '../../asset/wellness_stream/unmute.svg'
import mutedBlack from '../../asset/wellness_stream/muted_black.svg'
import mute from '../../asset/wellness_stream/mute.svg'
import React from 'react';
import ReactPlayer from 'react-player';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useWellnessStreamStore } from '../../store/wellnessStream';
import checkIsYoutube from '../../helpers/checkIsYoutube';
import Title from './Title';

export default function MobilePlayer({ src, index, feed }: any) {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  // const [isMuted, setIsMuted] = useState(true);
  const [isMobileMuted, setIsMobileMuted] = useState(true);
  const videoRef = useRef<ReactPlayer>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState<number>(0); // Track current time
  const [duration, setDuration] = useState<number>(0); // Track total duration
  const [seeking, setSeeking] = useState<boolean>(false); // To track if user is seeking
  // const { globalMute, setGlobalMute, setInitUnmute, initUnmute } = useWellnessStreamStore()
  const { globalMute, hasUserInteracted, setGlobalMute } = useWellnessStreamStore();
  // Using the react-intersection-observer hook to track visibility
  const { ref, inView } = useInView({
    threshold: 0.5, // Detect when at least 50% of the video is in view
    triggerOnce: false, // Keep checking when scrolling back into view
  });

  const [showPlayIcon, setShowPlayIcon] = useState(false);
  const isYouTube = checkIsYoutube(src);

  // Modify handlePlay to toggle play state and update play icon
  const handlePlay = () => {
    if (!videoRef.current) return;
    setPlaying(!playing);
    setShowPlayIcon(playing);
    setGlobalMute(false)
  };



  // Update progress based on video time
  const handleTimeUpdate = () => {
    if (!videoRef.current) return;
    const progress = (videoRef.current.getCurrentTime() / videoRef.current.getDuration()) * 100;
    setProgress(progress);
  };

  // Stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMute = () => {

    if (!globalMute) {
      setGlobalMute(true);

    }
    else {
      setGlobalMute(false);

    }
  }

  // Autoplay based on user interaction
  useEffect(() => {
    if (inView) {
      setGlobalMute(true)

      setTimeout(() => {
        setPlaying(true);  // Play video when it comes into view
        
        setGlobalMute(false)
      }, 100);
    } else {
      setPlaying(false); // Pause video when it goes out of view
    }
  }, [inView]);




  // Handler to track progress
  const handleProgress = (state: { playedSeconds: number }) => {
    if (!seeking) {
      setCurrentTime(state.playedSeconds); // Update current time
    }
  };

  // Handler for when the video is ready (to get duration)
  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  // Update these handlers to work with both mouse and touch events
  const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>) => {
    const target = e.currentTarget as HTMLInputElement;
    const time = parseFloat(target.value);
    setCurrentTime(time);
    if (videoRef.current) {
      videoRef.current.seekTo(time);
    }
  };

  const handleSeekStart = () => {
    setSeeking(true);
  };

  const handleSeekEnd = (e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>) => {
    setSeeking(false);
    const target = e.currentTarget as HTMLInputElement;
    const time = parseFloat(target.value);
    if (videoRef.current) {
      videoRef.current.seekTo(time);
    }
  };

  // Helper function to format time
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  const handleTouchMove = () => {
    setPlaying(false)
    setShowPlayIcon(true)
  }
  return (
    <div className={`player flex h-full aspect-[0.5625/1] justify-center items-center max-w-none w-full`} ref={ref} >
      <div className='w-full h-full'>
        {/* <div className={`flex items-center w-full h-12 bg-black`}>
        </div> */}
        <div className='videoPlayer relative group' onClick={handlePlay} onTouchMove={handleTouchMove}>
          {/* <div>
            <h1>User Interaction Detection</h1>
            {hasUserInteracted ? (
              <p>User has interacted with the page!</p>
            ) : (
              <p>No interaction detected yet.</p>
            )}
          </div> */}
          <ReactPlayer
            onPlay={ () => setShowPlayIcon(false)}
            onProgress={handleProgress}
            onDuration={handleDuration}
            ref={videoRef}
            url={src}  // This can be a YouTube URL or local video file
            playing={playing}  // Autoplay the video if it's in view
            muted={hasUserInteracted ? globalMute : !hasUserInteracted}  // If user interacted, unmute future videos
            controls={isYouTube ? true : false}  // Show native video controls
            loop
            width={'100%'}
            height={'calc(100dvh)'}
            playsinline
            config={{
              youtube: {
                playerVars: {
                  modestbranding: 1,  // Hides YouTube logo
                  fs: 0               // Disables fullscreen button
                }
              }
            }}
          />
          <div
            className={`${isYouTube ? 'absolute transform translate-x-1/2 -translate-y-1/2 top-[50%] right-[50%] h-[80%]' : 'absolute top-0 left-0 h-full'} w-full bg-transparent cursor-pointer`}

          >
            {showPlayIcon && !isYouTube && <PlayerIcon />}
          </div>
          {/* Custom Slider for Duration */}
          {!isYouTube ? <div className={`controls-wrapper ${playing ? 'opacity-0' : 'opacity-100'} transition-opacity duration-500 ease-in-out `}>
            <div className='time-display flex justify-between'>
              {formatTime(currentTime)} / {formatTime(duration)}
              {/* <img src={globalMute ? mute : unmute} alt="" className='w-6 h-6 mr-4 cursor-pointer' onClick={handleMute} /> */}

            </div>
            <input
              type='range'
              min={0}
              max={duration}
              step='0.01'
              value={currentTime}
              onMouseDown={handleSeekStart}
              onTouchStart={handleSeekStart}
              onChange={handleSeekChange}
              onMouseUp={handleSeekEnd}
              onTouchEnd={handleSeekEnd}
              className='seek-bar cursor-pointer'
            />
          </div> : ""}
        <Title feed={feed} className='bottom-[72px] left-2' />
        </div>
      </div>

      {/* {!playing && !isYouTube && <PlayerIcon onClick={handlePlay} />} */}

      <style>{`
      .videoPlayer iframe{
        // height: calc(100vh-3rem);
        width:100%;
        // width: 400px;
      }
        .player {
          position: relative;
          background: var(--black);
          overflow: hidden;
        }

        .player video {
          position: relative;
          // height: 100vh;
        }


      .controls-wrapper {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 10px;
        }
        .time-display {
          color: white;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .seek-bar {
          width: 100%;
          -webkit-appearance: none;
          background: transparent;
        }
        .seek-bar::-webkit-slider-runnable-track {
          width: 100%;
          height: 4px;
          background: #ddd;
        }
        .seek-bar::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background: white;
          margin-top: -4px;
        }

      `}</style>
    </div>
  );
}

