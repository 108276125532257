import { Navigate, Outlet } from 'react-router-dom';
import SideBar from '../components/SideBar';
import React, { useEffect } from 'react';
import { usePrivacyStore } from '../store/privacy';
import PinPopUp from '../components/setting/PinPopUp';
import { PulseLoader } from 'react-spinners';
import useAppInitialization from '../hooks/useAppInitialization';

const Unauthorized = () => (
    <div>
        <h1>Unauthorized</h1>
        <p>You do not have permission to view this page.</p>
    </div>
);

interface ProtectedRoutesProps {
    redirectPath?: string;
    hideFromWebView?: boolean;
    requiredRole?: string;
    hideSidebar?: boolean;
}

export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({
    redirectPath = '/login',
    hideFromWebView = false,
    requiredRole,
    hideSidebar = false,
}) => {
    const apiToken = sessionStorage.getItem('api_token');
    const { isShowLocked, autoLockDuration, isPrivacy, setIsShowLocked } = usePrivacyStore();
    const { loading } = useAppInitialization(apiToken);

    const storedUserData = sessionStorage.getItem('user');
    const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;

    useEffect(() => {
        if (!isPrivacy) return;

        const currentTime = new Date().getTime();
        localStorage.setItem('storedTime', currentTime.toString());

        const handleVisibilityChange = () => {
            const storedTime = localStorage.getItem('storedTime');
            const currentTime = new Date().getTime();

            if (document.visibilityState === 'visible' && storedTime) {
                const timeDifference = currentTime - parseInt(storedTime, 10);
                localStorage.setItem('storedTime', currentTime.toString());
                if (timeDifference >= autoLockDuration * 60 * 1000) {
                    setIsShowLocked(true);
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [isPrivacy, autoLockDuration, setIsShowLocked]);

    if (isShowLocked && !hideFromWebView) {
        return <PinPopUp isOpenPin={isShowLocked} />;
    }

    if (loading) {
        return (
            <div className="h-[100dvh] flex justify-center items-center">
                <PulseLoader />
            </div>
        );
    }

    if (!apiToken) {
        return <Navigate to={redirectPath} replace />;
    }

    if (requiredRole && parsedUserData.org_roles[0].role !== requiredRole) {
        return <Unauthorized />;
    }
    return (
        <>
            {!hideSidebar && <SideBar />}
            <Outlet />
        </>
    );
};
